import Vue from 'vue'

// --- Router ---
import Router from 'vue-router'
import {ROUTER} from "@/config/routes";
import store from "../store";

//prevent duplicate && Navigation cancelled route error
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;


// Override the push method to catch the NavigationDuplicated error
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Navigation cancelled')) {
            throw err;
        }
    });
};

// Override the replace method to catch the NavigationDuplicated error
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Navigation cancelled')) {
            throw err;
        }
    });
};

Vue.use(Router);

// --- ReMap Routes ---
let routes = [];

/* BUILD ROUTES FROM config/routes.js with groups and child */
ROUTER.ENTRIES.forEach((entry) => {
    if (entry.childs.length == 0 && entry.groups.length == 0) {
        routes.push({
            path: entry.path,
            props: entry.props,
            name: entry.name,
            meta: entry.meta,
            component: entry.component
        });
    } else {
        if (entry.childs.length > 0) {
            entry.childs.forEach((childs) => {
                routes.push({
                    path: childs.path,
                    props: childs.props,
                    name: childs.name,
                    meta: childs.meta,
                    component: childs.component
                });
            });
        }
        if (entry.groups.length > 0) {
            entry.groups.forEach((group) => {
                group.childs.forEach((groupChild) => {
                    routes.push({
                        path: groupChild.path,
                        props: groupChild.props,
                        name: groupChild.name,
                        meta: groupChild.meta,
                        component: groupChild.component
                    });
                });
            });
        }
    }
});

const router = new Router({
    mode: process.env.VUE_APP_ELECTRON === 'true' ? 'hash' : 'history',
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    routes: routes
});

const DEFAULT_TITLE = '3POS Kassensystem';

router.beforeEach(async (to, from, next) => {
    await store.restored;

    /*
    //CHECK IF BS ID / BS ACCESS STRING IS SET
    let accessString = "";
    if(to.query.hasOwnProperty("bsid")) {
        alert("test");

        accessString = to.query.bsid;
    }

    let posID = 0;
    if(to.query.hasOwnProperty("bs")) {
        posID = parseInt(to.query.bs);
    }

    let cashierID = 0;
    if(to.query.hasOwnProperty("cashierID")) {
        cashierID = parseInt(to.query.cashierID);
    }

    if(accessString.length > 0 && posID > 0) {
        this.axios.defaults.headers.common['3POSBS'] = posID;
        this.axios.defaults.headers.common['3POSBSID'] = accessString;

        this.$store.commit("api/auth/init", {
            accessString: accessString,
            posID: posID,
            cashierID: cashierID
        });
    }
    */

    if(to.path==='/resetPassword/' && to.query.hasOwnProperty('userId')&& to.query.hasOwnProperty('resetToken')){
        store.commit('api/auth/setResetPasswordCreds',{
            userId:to.query.userId,
            resetToken:to.query.resetToken,
        })
    }

    if(to.name === "pos.retail.selfcheckout"){
        store.commit("pos/retail/setSelfCheckout", true);
    }else if(store.getters["pos/retail/isSelfCheckout"]){
        store.commit("pos/retail/setSelfCheckout", false);
    }
    
    //CHECK ROUTE PERMISSION(MODULES + PERMISSION)
    if (!store.getters["permissions/checkRoutePermission"](to) || (store.getters["app/isPoselinio"] && store.getters["permissions/isRouteExcluded"](to))) {
        //REDIRECT TO DASHBOARD
        if (from.name !== "dashboard") {
            next({
                name: 'dashboard'
            });
        } else {
            return;
        }
    }

    next();
});

// SET SITE TITLE
router.afterEach(() => {
    document.title = DEFAULT_TITLE;
});


router.onError(error => {
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload()
    }
})


export default router;
