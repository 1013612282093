<template>
  <v-app class="black" :class="{ 'theme-poselinio': isPoselinio }">
    <div v-if="!appLoading" id="app">
      <component :is="layout">
        <div v-if="showLoading">
          <v-progress-linear :indeterminate="true" style="margin-top: -2px" />
          <p>{{ $t('generic.lang_dataisbeingloaded') }}</p>
        </div>

        <transition mode="out-in" name="fade">
          <router-view />
        </transition>
      </component>

      <!-- TSE ERROR DIALOG -->
      <v-dialog v-model="showTSEDialog" persistent width="500">
        <v-card>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              {{ $t('generic.lang_tseModuleNotReady') }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <div class="text-center" style="margin-top: 30px">
              {{ $t('generic.lang_tseModuleIsNotReadyYouCanContinueAnyway') }}
            </div>
          </v-card-text>

          <v-card-actions style="background-color: white">
            <v-spacer />
            <v-btn color="error" text @click="skipTSEWarning = true">{{ $t('generic.lang_stillFurther') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- WEB SOCKET CONNECTION -->
      <v-dialog :value="websocketWarning" persistent width="500" style="z-index: 1000">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{
              $t('generic.lang_noConnection')
            }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="text-center" style="font-size: 14px; margin-top: 10px">
            {{ $t('generic.lang_noConnectionText') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="refreshPage">{{ $t('generic.lang_refreshPage') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- NEW VERSION RELOAD DIALOG -->
      <v-dialog v-model="showNewVersionDialog" persistent width="500">
        <v-card>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              {{ $t('generic.lang_newVersionHeader') }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <div class="text-center" style="margin-top: 30px">
              <p v-html="$t('generic.lang_newVersionBody')"></p>
            </div>
          </v-card-text>

          <v-card-actions style="background-color: white">
            <v-spacer />
            <v-btn color="error" text @click="newVersionReloadPage">{{ $t('generic.lang_next') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- NO CAPACITOR/ELECTRON HYBRID CONNECTION -->
      <v-dialog :value="showHybridNetworkError" persistent width="500" style="z-index: 1000">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>{{
              $t('generic.lang_noConnection')
            }}</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="text-center" style="font-size: 14px; margin-top: 10px">
            {{ $t('generic.lang_noHybridConnectionText') }}
          </v-card-text>

          <v-card-actions>
            <v-btn color="error" text @click="showHybridNetworkError = false">
              {{ $t('generic.lang_close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <a class="text-white" :href="cloudPosLink" target="_blank">
              <v-btn color="primary">
                {{ $t('generic.lang_openPOSInCloud') }}
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <Snackbar />
    </div>

    <InitData v-if="!appLoading && !app.initDataLoaded && userLoggedIn" />

    <!-- Loading Spinner -->
    <div v-if="appLoading" style="text-align: center; margin-top: 100px">
      <v-progress-circular color="primary" indeterminate size="50" width="4"></v-progress-circular>
      <p style="margin-top: 20px">{{ $t('generic.lang_dataisbeingloaded') }}</p>
    </div>

    <!--<PWACheck></PWACheck> -->
    <offline-hybrid-dialog />
    <SocketTableController v-if="checkSocketTableRlatedModules" />
  </v-app>
</template>

<script>
import OfflineHybridDialog from '@/components/OfflineHybridDialog';
import { Events } from './plugins/events';
import InitData from './components/InitData';
import Snackbar from './components/Snackbar';
import { mapState, mapGetters } from 'vuex';
import SocketTableController from './components/common/SocketTableController';
import { handleCurrencyFormatting } from './plugins/currencyFormater';
import axios from "axios";
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins;

//import PWACheck from "./components/PWACheck";

const navig = navigator;

export default {
  components: {
    SocketTableController,
    OfflineHybridDialog,
    Snackbar,
    InitData,
    //PWACheck
  },

  data() {
    return {
      appLoading: true,
      default_layout: 'userpages',
      showLoading: false,
      skipTSEWarning: true, //TESTING ONLY
      showNewVersionDialog: false,
      showHybridNetworkError: false,
      kellnerSchlossToken: '',
      websocket: this.$socket,
      websocketConnectionAfterLoginClosed: null,
    };
  },
  watch: {
    websocketWarning(val) {
      if (!val) {
        if (
          this.$route.path === '/pos/gastro' &&
          this.$store.state.pos.gastro.table &&
          this.$store.getters['pos/gastro/tableName'] !== 0
        ) {
          this.$socket.emit('tableplan.getLockedTables');
          if (this.$vuetify.breakpoint.smAndDown) {
            this.$router.go(0);
          } else {
            this.$router.replace('/pos/gastro/tables');
          }
        }
      }
    },
    userLoggedIn(val) {
      if (val) {
        // USER NOW LOGGED IN
        this.websocketConnectionAfterLoginClosed = null;
      }
    },
    websocketConnectState(val) {
      if (!val) this.websocketConnectionAfterLoginClosed = true;
      else {
        if (this.websocketConnectionAfterLoginClosed === null)
          this.websocketConnectionAfterLoginClosed = false;
      }
    },
  },

  computed: {
    ...mapState(['app', 'user', 'printer', 'api']),
    ...mapGetters({
      isPoselinio: 'app/isPoselinio'
    }),
    cloudPosLink() {
      return `https://kasse.3pos.de/?bsid=${this.api.auth.accessString}&bs=${this.api.auth.posID}`;
    },
    checkSocketTableRlatedModules() {
      // 2, gastro
      // 54, tablebee
      return (
        this.$store.getters['permissions/checkModule'](54) &&
        this.$store.getters['permissions/checkModule'](2)
      );
    },
    websocketConnectState() {
      return this.websocket.connected;
    },
    websocketWarning() {
      // CHECK USER LOGGED IN
      if (!this.userLoggedIn) return false;

      // CHECK WEBSOCKET CONNECTED
      if (this.websocketConnectState) return false;

      if (this.websocketConnectionAfterLoginClosed === null) return false;

      if (this.websocketConnectionAfterLoginClosed === false) return false;

      return true;
    },
    userLoggedIn() {
      return this.user.loggedIn && this.$route.name !== 'init';
    },
    layout() {
      return (this.$route.meta.layout || this.default_layout) + '-layout';
    },
    showTSEDialog() {
      if (this.appLoading || !this.app.initDataLoaded) {
        return false;
      }

      if (this.$route.name === 'init') {
        return false;
      }

      if (this.printer.printers.hasOwnProperty('TSE')) {
        if (this.printer.printers.TSE[0].TSEStatus === 'READY') {
          return false;
        }
      }

      return !this.skipTSEWarning;
    },
  },

  mounted() {
    window.addEventListener('click', () => {
      navigator.vibrate =
        navigator.vibrate ||
        navigator.webkitVibrate ||
        navigator.mozVibrate ||
        navigator.msVibrate;

      if (navigator.vibrate && this.$vuetify.breakpoint.smAndDown)
        navig.vibrate(90);
    });

    window.addEventListener('keydown', (e) => {
      this.disableF5(e);
    });

    window.addEventListener('keyup', (e) => {
      this.disableF5(e);
    });

    //LOADING PROGRESS BAR
    Events.$on('toggleLoading', (data) => {
      this.showLoading = data;
    });

    Events.$on('api-error', (enable) => {
      if (process.env["VUE_APP_CAPACITOR"] === "true" && this.$store.getters['api/auth/hybridEnabled'] && this.$store.getters['api/auth/hybridAddress']) {
        this.showHybridNetworkError = enable;
      }
    });

    this.waitForStore();

    //CHECK AUTH TOKEN EXPIERY EVERY 10 MINUTES
    this.renewAuthToken();

    //CLERK TOKEN LOGOUT
    window.addEventListener('keypress', this.tokenLogout);

    //REFRESH IF CHUNK MISSING / UPDATE HAS BEEN DEPLOYED:
    this.$router.onError((error) => {
      if (
        error.message.includes('Loading chunk') &&
        error.message.includes('failed')
      ) {
        this.showNewVersionDialog = true;
      }
    });
  },

  methods: {
    async waitForStore() {
      await this.$store.restored;
      if ((process.env["VUE_APP_ELECTRON"] === "true" || process.env["VUE_APP_CAPACITOR"] === "true") && this.$store.getters['api/auth/hybridEnabled'] && this.$store.getters['api/auth/hybridAddress']) {
        axios.defaults.baseURL = this.$store.getters['api/auth/hybridAPIURL'];
        this.$socket.io.uri = this.$store.getters['api/auth/hybridSocketURL'];
      }
      else {
        this.$store.commit("api/auth/setHybridAddress", "");
        this.$store.commit("api/auth/setHybridEnabled", false);
      }

      //CHECK IF WE CAN QUICK CHANGE
      await this.checkQuickChange();

      //SET VUETIFY LANGUAGE
      this.$vuetify.lang.current = this.$store.getters['app/currentLanguage'];

      this.$root.$i18n.locale = this.$store.getters['app/currentLanguage'];

      this.$moment.locale(this.$store.getters['app/currentLanguage']);

      handleCurrencyFormatting(
        this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')
      );

      this.checkApiAuth();
    },
    async checkQuickChange() {
      const bs = new URL(location.href).searchParams.get('bsid');
      const bsid = parseInt(new URL(location.href).searchParams.get('bs'));
      let cashierId = parseInt(
        new URL(location.href).searchParams.get('cashier_id')
      );

      if (bsid > 0 && bs.length > 10) {
        if (isNaN(cashierId) || cashierId < 1) {
          cashierId = 1;
        }

        // CHECK IF WE ALREADY SET BS + CASHIERID
        if (
          parseInt(this.api.auth.posID) !== bsid ||
          (parseInt(this.api.auth.posID) === bsid &&
            parseInt(this.api.auth.cashierID) !== cashierId)
        ) {
          // INIT DATA
          this.$store.commit('api/auth/init', {
            accessString: bs,
            posID: bsid,
            cashierID: cashierId,
          });

          // REMOVE INIT STATE
          this.$store.commit('app/updateInitDataLoadedState', false);
        }
      }
    },
    newVersionReloadPage() {
      window.location.reload();
    },
    checkApiAuth() {
      let currentTimestamp = Math.floor(Date.now() / 1000);

      // PRE-SET AXIOS HEADERS
      if (parseInt(this.api.auth.posID) > 0) {
        this.axios.defaults.headers.common['3POSBS'] = parseInt(
          this.api.auth.posID
        );
        this.axios.defaults.headers.common['3POSBSID'] =
          this.api.auth.accessString;
      }

      // LOGOUT USER WHEN ACCESSING LOGIN PAGE
      if (this.$route.name === 'init') {
        this.$store.dispatch('user/logout', { vm: this }).then(() => {
          this.appLoading = false;
        });

        return;
      }

      if (!parseInt(this.api.auth.posID) > 0) {
        if (this.$route.name !== 'init') {
          this.$router
            .replace({
              name: 'init',
            })
            .then(() => {
              this.appLoading = false;
            });

          return;
        }
      }

      if (this.user.loggedIn) {
        if (this.api.auth.apiWebTokenExpiration > currentTimestamp) {
          this.axios.defaults.headers.common['3POSAPITOKEN'] =
            this.api.auth.apiWebToken;

          //API CHECK IF TOKEN IS VALId
          this.axios
            .post('get/3pos/')
            .then((res) => {
              if (res.data === 'LOCK') {
                //TOKEN INVALID
                this.$store.dispatch('user/logout', { vm: this }).then(() => {
                  this.axios.defaults.headers.common['3POSAPITOKEN'] = '';

                  if (this.$route.name !== 'init') {
                    this.$router
                      .replace({
                        name: 'init',
                      })
                      .then(() => {
                        this.appLoading = false;
                      });
                  } else {
                    this.appLoading = false;
                  }
                });
              } else {
                //USER IS LOGGED IN AND VALIDATED
                //START BACKGROUND SYNC
                this.$store
                  .dispatch('backgroundSync/start', {
                    lastUpdate: null,
                    lastRow: null,
                  })
                  .then(() => {
                    this.appLoading = false;
                  });

                if ((process.env["VUE_APP_ELECTRON"] === "true" || process.env["VUE_APP_CAPACITOR"] === "true") && this.$store.getters['api/auth/hybridEnabled'] && this.$store.getters['api/auth/hybridAddress']) {
                  this.$socket.io.uri = this.$store.getters['api/auth/hybridSocketURL'];
                }
                //CONNECT TO SOCKET SERVER
                this.$socket.io.opts.query = {
                  bsid: this.api.auth.accessString,
                  bs: parseInt(this.api.auth.posID),
                  apiToken: this.api.auth.apiWebToken,
                  cashierID: parseInt(this.api.auth.cashierID),
                  userID: parseInt(this.user.userID),
                  clientType: 1,
                };

                this.$socket.open();
              }
            })
            .catch((error) => {
              console.log(error);
              //TOKEN INVALID
              this.$store.dispatch('user/logout', { vm: this }).then(() => {
                this.axios.defaults.headers.common['3POSAPITOKEN'] = '';

                if (this.$route.name !== 'init') {
                  this.$router
                    .replace({
                      name: 'init',
                    })
                    .then(() => {
                      this.appLoading = false;
                    });
                } else {
                  this.appLoading = false;
                }
              });
            });
        } else {
          //TOKEN INVALID
          if (this.$route.name !== 'init') {
            this.$router
              .replace({
                name: 'init',
              })
              .then(() => {
                this.appLoading = false;
              });
          }
        }
      } else {
        if (this.$route.name !== 'init') {
          this.$router
            .replace({
              name: 'init',
            })
            .then(() => {
              this.appLoading = false;
            });
        }
      }
    },
    renewAuthToken() {
      this.$nextTick(() => {
        window.setInterval(() => {
          let currentTimestamp = Math.floor(Date.now() / 1000);
          let apiAUthToken = this.api.auth.apiWebTokenExpiration;

          if (
            apiAUthToken - 1800 <= currentTimestamp &&
            this.$route.name !== 'init'
          ) {
            //RENEW AUTH TOKEN
            this.axios
              .post('get/renewAuthToken/')
              .then((res) => {
                if (res.data) {
                  //UPDATE API AUTH TOKEN EXPIRATION
                  let JWTtimestamp = Math.floor(Date.now() / 1000);
                  let localTimestampOffset =
                    JWTtimestamp - parseInt(res.data.unixTime);
                  let JWTexpTS =
                    parseInt(res.data.expTime) + localTimestampOffset;

                  //UPDATE API AUTH TOKEN
                  this.$store.dispatch(
                    'api/auth/updateToken',
                    res.data.authToken
                  );
                  this.$store.dispatch(
                    'api/auth/updateTokenExpiration',
                    JWTexpTS
                  );

                  this.axios.defaults.headers.common['3POSAPITOKEN'] =
                    this.api.auth.apiWebToken;
                }
              })
              .catch((error) => {
                console.log(error);
                //TOKEN INVALID
                this.$store.dispatch('user/logout', { vm: this }).then(() => {
                  this.axios.defaults.headers.common['3POSAPITOKEN'] = '';

                  if (this.$route.name !== 'init') {
                    this.$router
                      .replace({
                        name: 'init',
                      })
                      .then(() => {
                        this.appLoading = false;
                      });
                  } else {
                    this.appLoading = false;
                  }
                });
              });
          }
          //ELSE SLEEP
        }, 300000); //5 minutes
      });
    },

    tokenLogout(e) {
      //CHECK IF CLERK TOKEN KEY IS ACTIVE IN MODULES
      if (this.$store.getters['permissions/checkModule'](26)) {
        //GET KELLNERSCHLOSS
        if (e.which !== 13) {
          this.kellnerSchlossToken += String.fromCharCode(e.keyCode);
        }
        if (e.which === 13) {
          if (this.kellnerSchlossToken == 'Out') {
            if (this.user.loggedIn) {
              this.axios.post('get/logout/');
              this.$store.dispatch('user/logout', { vm: this }).then(() => {
                this.$router.replace({
                  name: 'init',
                });
              });
            }
          }
          this.kellnerSchlossToken = '';
        }
      }
    },
    disableF5(e) {
      if ((e.which || e.keyCode) === 116) e.preventDefault();
    },
    refreshPage() {
      window.location.reload();
    }
  },
  destroyed() {
    window.removeEventListener('keypress', this.tokenLogout);
    Events.$off('api-error');
  },
};
</script>

<style lang="scss">
@import 'assets/base.scss';
</style>
