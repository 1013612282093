export default {
    namespaced: true,

    state: {
        permissions: [],
        modules: [],
        excludedModulesPoselinio: [
            4,
            12,
            13,
            38,
            41,
            54,
            56,
            60,
            63,
            65,
            67,
            68,
            78,
            85
        ],
        excludedRoutesPoselinio: [
            "erp.storage",
            "erp.baseData.garnish.of.the.day",
            "erp.baseData.IngredientGroups",
            "erp.baseData.ingredients",
            "erp.baseData.itemPackages",
            "erp.baseData.itemRecipes",
            "erp.baseData.priceTMPL",
            "erp.baseData.voucher",
            "erp.baseData.voucherDetails",
            "erp.baseData.giftCard",
            "erp.baseData.giftCardDetails",
            "erp.baseData.inventoryItems",
            "erp.baseData.happyHour",
            "erp.baseData.dailygoods",
            "erp.settings.mealSizes",
            "erp.settings.deposit",
            "erp.settings.importItemControllSystemData",
            "erp.settings.mealType",
            "erp.settings.packages",
            "erp.settings.unitManagement",
            "pos.externalAppGroup",
            "settings.moduleSettings.tipManager",
            "accounting.unpaidInvoices",
            "accounting.datev.sapreport",
            "customer.custGroups",
        ],
        hybrid: 0,
    },

    mutations: {
        update(state, data) {
            state.permissions = data.permissions;
            state.modules = data.modules;
            state.hybrid = data.hybrid;
        },
    },
    actions: {
        update({commit}, data) {
            commit('update', data);
        },
    },
    getters: {
        excludedModulesPoselinio: (state) => state.excludedModulesPoselinio,
        excludedRoutesPoselinio: (state) => state.excludedRoutesPoselinio,
        modules: (state) => state.modules,
        checkModule(state) {
            const isPoselinio = window.store.getters['app/isPoselinio']
            return (moduleID) => {
                return state.modules.includes(moduleID) || (isPoselinio && !state.excludedModulesPoselinio.includes(moduleID));
            }
        },
        checkPermission: (state) => (permission) => {
            return state.permissions.includes(permission);
        },
        /* CHECKS IF DISPLAYABLE WHEN USING HYBRIDSERVER (SERVER / HYBRID MODE) */
        checkHybridEnabled: (state) => (hybridType) => {
            if (state.hybrid > 0) {
                //DISPLAY ONLY ON HYBRID
                if (hybridType == "Hybrid") {
                    if (process.env.VUE_APP_HYBRID == "true" || process.env.VUE_APP_ELECTRON == "true" || process.env.VUE_APP_CAPACITOR == "true") {
                        return true;
                    } else {
                        return false;
                    }
                    //DISPLAY ONLY ON SERVER
                } else if (hybridType == "Server") {
                    if (process.env.VUE_APP_HYBRID == "true" || process.env.VUE_APP_ELECTRON == "true" || process.env.VUE_APP_CAPACITOR == "true") {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                //If no Hybrid Display everytime
                return true;
            }
        },
        checkHybridMode: (state) => (hybridType) => {
            if (state.hybrid > 0) {
                //DISPLAY ONLY ON HYBRID
                if (hybridType == "Hybrid") {
                    if (process.env.VUE_APP_HYBRID == "true" || process.env.VUE_APP_ELECTRON == "true" || process.env.VUE_APP_CAPACITOR == "true") {
                        return true;
                    } else {
                        return false;
                    }
                    //DISPLAY ONLY ON SERVER
                } else if (hybridType == "Server") {
                    if (process.env.VUE_APP_HYBRID == "true" || process.env.VUE_APP_ELECTRON == "true" || process.env.VUE_APP_CAPACITOR == "true") {
                        return false;
                    } else {
                        return true;
                    }
                }
            }
            return false;
        },
        checkHybridState: (state) => {
            return state.hybrid > 0;
        },
        checkHybridApp: (state) => {
            return state.hybrid > 0 && (process.env.VUE_APP_ELECTRON == 'true' || process.env.VUE_APP_CAPACITOR == 'true')
        },
        // poselinio checks
        isRouteExcluded: (state) => (route) => {
            // Condition 1: Check if requiredModule is in excludedModulesPoselinio
            if (
                route.meta &&
                route.meta.permission &&
                typeof route.meta.permission.requiredModule === "number" &&
                state.excludedModulesPoselinio.includes(route.meta.permission.requiredModule)
            ) {
                return true;
            }

            // Condition 2: Check if any excluded route is a substring of route.name
            if (
                typeof route.name === "string" &&
                state.excludedRoutesPoselinio.some(
                    (excluded) => typeof excluded === "string" && route.name.includes(excluded)
                )
            ) {
                return true;
            }

            // Special case for groups
            if (route.meta && route.meta.hideInPoselinio)
                return true;

            // If neither condition is met, return false
            return false;
        },
        checkRoutePermission: (state) => (route) => {
            if (route.hasOwnProperty("meta")) {
                if (route.meta.hasOwnProperty("permission")) {
                    //MODULE
                    if (route.meta.permission.hasOwnProperty("requiredModule")) {
                        if(Array.isArray(route.meta.permission.requiredModule)){
                            let requiredModules = new Set(route.meta.permission.requiredModule);
                            if (!state.modules.some(module => requiredModules.has(module)))
                                return false;
                        }else{
                            if (!state.modules.includes(route.meta.permission.requiredModule) && route.meta.permission.requiredModule > 0)
                                return false;
                        }
                    }

                    //CHECK IF ACTIVATED ON HYBRID/SERVER OR DEACTIVED
                    if (state.hybrid > 0) {
                        if (route.meta.permission.hasOwnProperty("hybridDisplay")) {
                            if (process.env.VUE_APP_HYBRID == "true" || process.env.VUE_APP_ELECTRON == "true" || process.env.VUE_APP_CAPACITOR == "true")  {
                                //WE'RE ON HYBRIDSERVER
                                if (route.meta.permission.hybridDisplay == "Server") {
                                    return false;
                                }
                            } else {
                                //WE'RE ON CLOUD
                                if (route.meta.permission.hybridDisplay == "Hybrid") {
                                    return false;
                                }
                            }
                        }
                    }

                    //USER PERMISSION
                    if (route.meta.permission.hasOwnProperty("requiredPermission")) {
                        if (route.meta.permission.requiredPermission.length === 0)
                            return true;

                        if (!state.permissions.includes(route.meta.permission.requiredPermission))
                            return false;
                    }
                }
            }

            return true;
        }
    }
};
