// Time constants in minutes
const MINUTES_OFFLINE_THRESHOLD = 10;             // Show offline dialog after 10 minutes
const DAYS_OFFLINE_THRESHOLD = 60 * 24 * 10;      // 10 days in minutes
const DIALOG_REAPPEAR_INTERVAL_HOURS = 1;         // Show dialog again after 1 hour

// Time conversion constants
const MS_PER_MINUTE = 60000;
const MS_PER_HOUR = 3600000;

export default {
    namespaced: true,

    state: {
        isOfflineForHybridServer: false,
        isOfflineForCloudServer: false,
        isHybridServerOfflineOrInStop: false,
        last_hybrid_dialog_appear: isNaN(parseInt(localStorage.getItem("last_hybrid_dialog_appear"))) ? 0 : parseInt(localStorage.getItem("last_hybrid_dialog_appear")),
        lastHybridContact: 0,
    },
    
    getters: {
        IsOfflineForHybridServer: state => state.isOfflineForHybridServer,
        IsOfflineForCloudServer: state => state.isOfflineForCloudServer,
        lastHybridDialogAppear: state => state.last_hybrid_dialog_appear,
        IsHybridServerOfflineOrInStop: state => state.isHybridServerOfflineOrInStop,
        lastHybridContact: state => state.lastHybridContact,
    },
    
    mutations: {
        setLastHybridContact(state, payload) {
            state.lastHybridContact = Number(payload);
        },
        
        lastHybridDialogAppear(state) {
            state.last_hybrid_dialog_appear = new Date().getTime();
            localStorage.setItem("last_hybrid_dialog_appear", new Date().getTime() + "");
        },
        
        Is_OfflineForHybridServer(state, payload) {
            state.isOfflineForHybridServer = payload;
        },
        
        Is_OfflineForCloudServer(state, payload) {
            state.isOfflineForCloudServer = payload;
        },
        
        IsHybridServerOfflineOrInStop: (state) => {
            return state.isHybridServerOfflineOrInStop;
        },
        
        turnOfflineForCloudServer(state) {
            state.isOfflineForCloudServer = true;
        },
        
        turnOnlineForCloudServer(state) {
            state.isOfflineForCloudServer = false;
        },
        
        turnOfflineForHybridServer(state) {
            state.isOfflineForHybridServer = true;
        },
        
        turnOnlineForHybridServer(state) {
            state.isOfflineForHybridServer = false;
        },
        
        /**
         * Set hybrid server status based on 'get/dbSync/' response
         * Handles different scenarios based on server status and timing thresholds
         */
        checkStatus(state, payload) {
            // Check for hybrid server mode
            if (payload.hybridStatus && payload.hybridStatus.status && window.store.getters["permissions/checkHybridEnabled"]("Hybrid")) {
                // Save the last hybrid contact timestamp in store
                this.commit('hybrid/setLastHybridContact', payload.hybridStatus.lastHybridContact);
                
                // Calculate minutes since last sync
                let timeSinceLastSync = (Math.round(new Date().getTime()) - (Number(payload.hybridStatus.lastHybridContact) * 1000)) / MS_PER_MINUTE;
                
                // Case 1: Offline for more than threshold minutes but less than stop threshold (10 days)
                if (timeSinceLastSync >= MINUTES_OFFLINE_THRESHOLD && timeSinceLastSync < DAYS_OFFLINE_THRESHOLD) {
                    // Check if it's time to show the dialog (first time or after interval)
                    const hoursSinceLastDialog = (new Date().getTime() - state.last_hybrid_dialog_appear) / MS_PER_HOUR;
                    
                    if (state.last_hybrid_dialog_appear === 0 || (state.last_hybrid_dialog_appear > 0 && hoursSinceLastDialog >= DIALOG_REAPPEAR_INTERVAL_HOURS)) {
                        state.isOfflineForHybridServer = true;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: true
                        });
                    } else {
                        state.isOfflineForHybridServer = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: false
                        });
                    }
                    return;
                } 
                // Case 2: Offline for extremely long period (10+ days) - system might be in stop state
                else if (timeSinceLastSync >= DAYS_OFFLINE_THRESHOLD) {
                    const hoursSinceLastDialog = (new Date().getTime() - state.last_hybrid_dialog_appear) / MS_PER_HOUR;
                    
                    if (state.last_hybrid_dialog_appear === 0 || (state.last_hybrid_dialog_appear > 0 && hoursSinceLastDialog >= DIALOG_REAPPEAR_INTERVAL_HOURS)) {
                        state.isHybridServerOfflineOrInStop = true;
                        state.isOfflineForHybridServer = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: true,
                            type: "stop"
                        });
                    } else {
                        state.isHybridServerOfflineOrInStop = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: false,
                            type: "stop"
                        });
                    }
                    return;
                }

                state.isOfflineForCloudServer = false;
            } 
            // Check for server mode
            else if (payload.hybridStatus && payload.hybridStatus.status && window.store.getters["permissions/checkHybridEnabled"]("Server")) {
                // Save the last hybrid contact timestamp in store
                this.commit('hybrid/setLastHybridContact', payload.hybridStatus.lastHybridContact);
                
                // Calculate minutes since last sync
                let timeSinceLastSync = (Math.round(new Date().getTime()) - (Number(payload.hybridStatus.lastHybridContact) * 1000)) / MS_PER_MINUTE;
                
                // Show dialog if server is offline for more than threshold
                if (timeSinceLastSync >= MINUTES_OFFLINE_THRESHOLD) {
                    state.isOfflineForCloudServer = true;
                    
                    // Determine if dialog should appear based on last appearance time
                    const minutesSinceLastDialog = (new Date().getTime() - state.last_hybrid_dialog_appear) / MS_PER_MINUTE;
                    
                    if (state.last_hybrid_dialog_appear === 0 || (state.last_hybrid_dialog_appear > 0 && minutesSinceLastDialog >= MINUTES_OFFLINE_THRESHOLD)) {
                        window.eventBus.$emit("status", {
                            server: "cloud",
                            status: true
                        });
                    }
                } else {
                    state.isOfflineForCloudServer = false;
                }
                
                state.isOfflineForHybridServer = false;
                state.isHybridServerOfflineOrInStop = false;
            } 
            // Default case - no server issues
            else {
                state.isOfflineForCloudServer = false;
                state.isOfflineForHybridServer = false;
                state.isHybridServerOfflineOrInStop = false;
                window.eventBus.$emit("status", {
                    server: "both",
                    status: false
                });
            }
        }
    }
}