export let customerRoutes = {

    meta: {
        menuTitle: ('customers.lang_nav_custmanagement'),
        icon: 'lnr-user',
    },
    childs: [
        {
            path: '/customer/customers',
            name: 'customer.customers',
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'lnr-user',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "search_cust",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/SearchCustomers')
        },
        {
            path: '/customer/createCustomer',
            name: 'customer.createCustomer',
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'lnr-user',
                hideInNavigation: true,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "create_cust",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/CreateCustomer')
        },
        {
            path: '/customer/editCustomer/:id',
            name: 'customer.editCustomer',
            props: true,
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'lnr-user',
                hideInNavigation: true,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "edit_cust",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/EditCustomer')
        },
        {
            path: '/customer/createCustomer/project/:id',
            name: 'customer.createCustomer.project',
            props: true,
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'lnr-user',
                hideInNavigation: true,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "edit_cust",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/CreateCustomerProject')
        },
        {
            path: '/customer/editCustomer/project/:id',
            name: 'customer.editCustomer.project',
            props: true,
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'lnr-user',
                hideInNavigation: true,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "edit_cust",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/EditCustomerProject')
        },
        //start of customer groups
        {
            path: '/customer/custGroups',
            name: 'customer.custGroups',
            meta: {
                siteTitle: ('customers.lang_nav_custgroup'),
                menuTitle: ('customers.lang_nav_custgroup'),
                layout: 'default',
                icon: 'lnr-users',
                hideInPoselinio: true,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/customers/custumergroups/CustomerGroup')
        },
        {
            path: '/customer/custGroups/create',
            name: 'customer.custGroups.create',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/customers/custumergroups/CustomerGroupCreate')
        },
        {
            path: '/customer/custGroups/edit/:id',
            name: 'customer.custGroups.edit',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/custumergroups/CustomerGroupEdit')
        },//end of customer groups
        //start of customer Settings
        {
            path: '/customer/customerSettings',
            name: 'customer.customerSettings',
            meta: {
                siteTitle: ('customers.lang_customerSettings'),
                menuTitle: ('customers.lang_customerSettings'),
                layout: 'default',
                icon: 'pe-7s-tools',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/customers/customersettings/CustomerSettings')
        },
        {
            path: '/customer/customerSalutation/create',
            name: 'customer.customerSalutation.create',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/customersettings/salutation/CreateSalutation')
        },
        {
            path: '/customer/customerSalutation/edit/:id',
            name: 'customer.customerSalutation.edit',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/customersettings/salutation/EditSalutation')
        },
        {
            path: '/customer/customerDepartment/create',
            name: 'customer.customerDepartment.create',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/customersettings/departments/CreateDepartment')
        },
        {
            path: '/customer/customerDepartment/edit/:id',
            name: 'customer.customerDepartment.edit',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'lnr-users',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerGroupSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/customersettings/departments/EditDepartment')
        },
        {
            path: '/customer/customerImport',
            name: 'customer.customerImport',
            meta: {
                siteTitle: 'customers.lang_customerImport',
                menuTitle: 'customers.lang_customerImport',
                layout: 'default',
                icon: '',
                permission: {
                    requiredModule: 0,
                    requiredPermission: '',
                    hybridDisplay: 'Server',
                },
            },
            component: () => import('../views/customers/CustomerImportView'),
        },

        /*
        {
            path: '/customer/custCards',
            name: 'customer.custCards',
            meta: {
                siteTitle: ('customers.lang_custCards'),
                menuTitle: ('customers.lang_custCards'),
                layout: 'default',
                icon: 'pe-7s-credit',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerCardsSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/common/404.vue')
        },
        {
            path: '/customer/custNewsLetter',
            name: 'customer.custNewsLetter',
            meta: {
                siteTitle: ('customers.lang_cust_newsletter'),
                menuTitle: ('customers.lang_cust_newsletter'),
                layout: 'default',
                icon: 'pe-7s-mail-open-file',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customerNewsletterSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/common/404.vue')
        },
        {
            header: false,
            path: '/customer/customersettings',
            name: 'customer.customersettings',
            meta: {
                siteTitle: ('customers.lang_customerSettings'),
                menuTitle: ('customers.lang_customerSettings'),
                layout: 'default',
                icon: 'pe-7s-config',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "customersettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/common/404.vue')
        }*/
    ],
    groups: []
};